import { createApiMutationHook, IApiMutationFunction } from "../../utils/api";
import { IUserAuthenticationTokens } from "../types/IUserAuthenticationTokens";

export interface IUserLoginMutationParameters {
    email: string;
    password: string;
}

export type IUserLoginMutationResult = IUserAuthenticationTokens;

export const userLoginMutation: IApiMutationFunction<IUserLoginMutationParameters> = data => {
    return {
        method: "POST",
        url: "/login",
        data,
    };
};

export const useUserLoginMutation = createApiMutationHook<IUserLoginMutationParameters, IUserLoginMutationResult>(
    userLoginMutation,
);
