import { Space } from "antd";
import { ReactNode, useMemo } from "react";
import styled from "styled-components";

import { useLanguages } from "@sol/sdk";
import { Select } from "@sol/uikit/data-entry/Select";
import { SelectVariantsProps } from "@sol/uikit/molecules/Forms/Select/variants";

type Props = {
    label: string | ReactNode;
    onSelect: (value: string | undefined) => void;
    disabled?: boolean;
    defaultValue: string;
    value?: string;
    locale?: boolean;
    isLoading?: boolean;
    className?: string;
} & SelectVariantsProps;

const StyledSelect = styled(Select)`
    .ant-select-item-option-state {
        display: none;
    }
` as typeof Select;

const LanguageSelect = ({ onSelect, disabled, defaultValue, locale: useLocaleAsKey, value, className }: Props) => {
    const { data: languages, isLoading } = useLanguages({});

    const languageOptions = useMemo(
        () =>
            languages?.["hydra:member"]?.map(language => {
                const { title, locale } = language;

                return {
                    value: useLocaleAsKey ? locale : language["@id"],
                    label: title,
                    locale: locale,
                    suffixIcon: <img src={`/static/flags/${locale.substring(0, 2)}.svg`} alt="" className="w-6" />,
                };
            }) ?? [],
        [languages, useLocaleAsKey],
    );

    return (
        <div>
            <StyledSelect
                suffixIcon={languageOptions.find(o => value === o.value)?.suffixIcon}
                options={languageOptions}
                disabled={disabled}
                className={className}
                defaultValue={value ?? defaultValue}
                value={value}
                onChange={option => {
                    onSelect(option as string);
                }}
                loading={isLoading}
                size={"large"}
                optionRender={option => (
                    <Space className={"flex w-full justify-between"}>
                        {option.data.label}
                        <span className="flex items-center gap-3" lang={option.data.locale}>
                            {option.data.suffixIcon}
                            {option.data.title}
                        </span>
                    </Space>
                )}
            />
        </div>
    );
};

export default LanguageSelect;
