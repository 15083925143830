import { cva, type VariantProps } from "class-variance-authority";

export type IconButtonVariantProps = VariantProps<typeof iconButtonVariants>;

const baseClasses = [
    "flex",
    "items-center",
    "justify-center",
    "gap-3",
    "rounded-full",
    "transition-all",
    "duration-200",
    "ease-in-out",
    "focus:outline-0",
    "overflow-hidden",
    "bg-transparent-base",
    "text-purple-base",
    "focus:ring-blue-focus",
    "focus:ring-offset-2",
    "focus:ring-offset-white-base",
    "focus:ring",
    "disabled:fill-grey-base",
    "disabled:stroke-grey-base",
    "hover:bg-purple-base",
    "hover:text-white-base",
];

export const iconButtonVariants = cva(baseClasses, {
    variants: {
        position: {
            left: ["flex-row"],
            right: ["flex-row-reverse"],
        },
        variant: {
            primary: [
                "enabled:hover:border-purple-base",
                "enabled:hover:bg-purple-base",
                "active:border-purple-base",
                "active:bg-purple-base",
            ],
            secondary: [
                "bg-white-base",
                "enabled:hover:border-purple-base",
                "enabled:hover:bg-purple-base",
                "active:border-purple-base",
                "active:bg-purple-base",
            ],
        },
    },
    defaultVariants: {
        position: "left",
        variant: "primary",
    },
});
