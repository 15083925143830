import { GetStaticPropsResult } from "next";
import React from "react";
import { useTranslation } from "react-i18next";
import useLocale from "src/hooks/useLocale";
import { Text } from "src/uikit";
import styled from "styled-components";

import AnalyticsPage from "../components/AnalyticsPage";
import LoginForm from "../components/auth/LoginForm";
import LoginFormText from "../components/auth/LoginForm/LoginFormText";
import MetaTitle from "../components/MetaTitle";
import LanguageSelect from "../components/shared/LanguageSelect";

const MandatoryLabel = styled(Text)`
    --text-color: ${({ theme }) => theme.palette.red.base};
    text-align: right;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

export async function getStaticProps() {
    const result: GetStaticPropsResult<{
        title: string;
    }> = {
        props: { title: "page.login.title" },
    };

    return result;
}

const Login = () => {
    const [t] = useTranslation();

    const [locale, setLocale] = useLocale();

    return (
        <>
            <MetaTitle title={t("page.login.title")} />

            <MandatoryLabel variant="subheading">{t("page.login.mandatoryFields")}</MandatoryLabel>
            <LoginForm />
            <LanguageSelect
                className="mt-3 !w-full"
                label={t("page.login.language.label")}
                onSelect={setLocale}
                defaultValue={locale}
                value={locale}
                locale
            />
            <LoginFormText />
            <AnalyticsPage />
        </>
    );
};

export default styled(Login)`
    ${LoginFormText} {
        margin-top: ${({ theme }) => theme.spacing[6]};
    }
`;
