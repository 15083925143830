import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { useCurrentBrand } from "@sol/sdk/brands/useCurrentBrand";

const LoginFormBoldText = styled(Text)`
    font-weight: 600;
`;

const LoginFormNormalText = styled(Text)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    line-height: 1.8rem;
`;

const LoginFormListContainer = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
`;

const LoginFormListItem = styled(Text)`
    &::before {
        content: "•";
        margin-right: ${({ theme }) => theme.spacing[2]};
        color: ${({ theme }) => theme.palette.purple.base};
    }
`;

type Props = {
    className?: string;
};

const LoginFormText = ({ className }: Props) => {
    const [t] = useTranslation();
    const { data: currentBrand } = useCurrentBrand({});

    return (
        <div className={className}>
            <LoginFormBoldText as="h2">{t("form.login.content.needYourIdsQuestion")}</LoginFormBoldText>
            <LoginFormNormalText>{t("form.login.content.askYourInvitationTo")} :</LoginFormNormalText>
            <LoginFormListContainer>
                <LoginFormListItem as="li">{t("form.login.content.yourTrainer")}</LoginFormListItem>
                <LoginFormListItem as="li">{t("form.login.content.yourManager")}</LoginFormListItem>
                {currentBrand?.brand && (
                    <LoginFormListItem as="li">
                        <Text
                            variant="inline-link"
                            as="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`mailto:${currentBrand?.brand?.contactEmail}?subject=${t(
                                "form.login.content.askForAccessMailTitle",
                            )}`}
                        >
                            {currentBrand?.brand?.contactEmail}
                        </Text>{" "}
                        {t("form.login.content.forAdminAccess")}
                    </LoginFormListItem>
                )}
            </LoginFormListContainer>
        </div>
    );
};

export default styled(LoginFormText)``;
