import { useObjectRef } from "@react-aria/utils";
import { HTMLAttributes } from "react";
import { AriaTextFieldOptions, useTextField } from "react-aria";
import { UseControllerProps } from "react-hook-form";
import styled from "styled-components";

import { useFormSchema } from "@sol/forms/useFormSchema";
import Input from "@sol/uikit/data-entry/Input";
import Alert from "@sol/uikit/feedback/Alert";
import Label from "@sol/uikit/inputs/Label";

import { type BaseFieldProps, useFieldInfo } from "./useFieldInfo";

type Props = {
    className?: string;
    containerAttributes?: HTMLAttributes<HTMLDivElement>;
} & BaseFieldProps &
    UseControllerProps &
    AriaTextFieldOptions<"input">;

const NewTextField = ({ className, containerAttributes, ...props }: Props) => {
    const schema = useFormSchema();

    const fieldInfo = useFieldInfo(props, schema);

    const { label, hint, isRequired, errorMessage, max, value = "", ref: fieldRef } = fieldInfo;
    const ref = useObjectRef(fieldRef);
    const { inputProps, labelProps, errorMessageProps } = useTextField(
        { ...fieldInfo, ...props, value: value ?? "" },
        ref,
    );

    return (
        <div className={className} {...containerAttributes}>
            {label ? (
                <Label {...labelProps} isRequired={isRequired} hint={hint} max={max} value={value}>
                    {label}
                </Label>
            ) : null}
            <Input
                {...inputProps}
                status={errorMessage ? "error" : undefined}
                aria-invalid={`${!!errorMessage}`}
                size={"large"}
            />
            {errorMessage ? (
                <Alert {...errorMessageProps} size="small" intent="error">
                    {errorMessage}
                </Alert>
            ) : null}
        </div>
    );
};

export default styled(NewTextField)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
`;
