import { Modal as AModal, ModalProps } from "antd";

type Props = ModalProps;

const Modal = ({ ...props }: Props) => {
    return <AModal {...props} />;
};

Modal.info = AModal.info;
Modal.success = AModal.success;
Modal.error = AModal.error;
Modal.warning = AModal.warning;
Modal.confirm = AModal.confirm;
Modal.useModal = AModal.useModal;

export default Modal;
