import { yupResolver } from "@hookform/resolvers/yup";
import { cva } from "class-variance-authority";
import Link from "next/link";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { InferType } from "yup";

import { FormSchemaProvider } from "@sol/forms";
import NewTextField from "@sol/forms/fields/NewTextField";
import { Route } from "@sol/routing";
import { useUserLoginMutation } from "@sol/sdk/users/mutations/userLoginMutation";
import { Typography } from "@sol/uikit/core/Typography";
import { Button } from "@sol/uikit/general/Button";

import { loginFormSchema } from "./schema";
import analytics from "../../../analytics";
import { AnalyticsEvent } from "../../../analytics/Analytics";
import { setToken } from "../../../store/authentication/actions";

type LoginFormValues = InferType<typeof loginFormSchema>;

type Props = {
    className?: string;
};

const LoginForm = ({ className }: Props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const submitButtonRef = useRef(null);

    const form = useForm<LoginFormValues>({
        mode: "all",
        resolver: yupResolver(loginFormSchema),
        async defaultValues() {
            return loginFormSchema.getDefault();
        },
        shouldFocusError: true,
    });

    const [login, { isLoading, reset }] = useUserLoginMutation({
        onSuccess: data => {
            analytics.track(AnalyticsEvent.LoginSuccess);
            dispatch(setToken(data));
        },
    });

    const onSubmit = async (values: LoginFormValues) => {
        reset();
        try {
            await login(values, { throwOnError: true });
        } catch (error) {
            analytics.track(AnalyticsEvent.LogiFailure);

            if (error?.data) {
                form.setError("email", {
                    type: error.data.status,
                    message: error.data.message,
                });

                form.setError("password", {
                    type: error.data.status,
                    message: error.data.message,
                });

                form.setFocus("email");
            }
        }
    };

    const submit = form.handleSubmit(onSubmit);
    const { isSubmitting, isValid } = form.formState;

    const formClasses = cva(["flex", "flex-col", "gap-6"]);

    return (
        <FormProvider {...form}>
            <FormSchemaProvider schema={loginFormSchema}>
                <form className={formClasses({ className })} data-cy="login-form">
                    <NewTextField
                        name="email"
                        label={t("form.login.email.label")}
                        hint={t("form.login.email.helper")}
                        autoComplete="email"
                        data-cy="email"
                        errorMessage={form.formState.errors.email}
                    />
                    <div>
                        <NewTextField
                            name="password"
                            label={t("form.login.password.label")}
                            autoComplete="current-password"
                            type="password"
                            data-cy="password"
                        />
                        <Link href={Route.RESET_PASSWORD} passHref>
                            <Typography variant="paragraph" tag="a" className="text-purple-base underline">
                                {t("form.login.forgotPasswordLink")}
                            </Typography>
                        </Link>
                    </div>
                    <Button
                        ref={submitButtonRef}
                        htmlType="submit"
                        type="primary"
                        disabled={!isValid || isSubmitting || isLoading}
                        className="mx-0 w-full"
                        size="large"
                        onClick={() => submit()}
                    >
                        {t("form.login.submit")}
                    </Button>
                </form>
            </FormSchemaProvider>
        </FormProvider>
    );
};

export default LoginForm;
