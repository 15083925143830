import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";
import { FC } from "react";

import { ChevronDown } from "@sol/uikit/core/icons/Arrows";
import { Check01 } from "@sol/uikit/core/icons/General";
export type SelectProps = AntSelectProps;

export const Select: FC<SelectProps> & {
    Option: typeof AntSelect.Option;
} = props => {
    const { suffixIcon, menuItemSelectedIcon, ...rest } = props;
    return (
        <AntSelect
            {...rest}
            menuItemSelectedIcon={menuItemSelectedIcon ?? <Check01 variant="antd" size={16} />}
            suffixIcon={rest.loading ? undefined : suffixIcon || <ChevronDown variant="antd" size={16} />}
        />
    );
};

Select.Option = AntSelect.Option;
